import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingOutlined, PlusOutlined, } from '@ant-design/icons';
import { App, Button, Input, Spin, Tag, } from 'antd';
import * as ProjectAPI from 'api/project-api';
import * as TeamAPI from 'api/team-api';
import { useSession } from 'components/Authenticated';
import { txt } from 'libs/i18n';
import { useEffect, useRef, useState, } from 'react';
import { deriveTagColor } from 'libs/tag-style';
import styles from './styles.module.less';
import TagCheckbox from './TagCheckbox';
const TagsBulkEditDialog = ({ selectedProjects, onRefetch, onCancel, onRefetchTags, }) => {
    const { session } = useSession();
    const { message } = App.useApp();
    const [tagInput, setTagInput] = useState({
        visible: false,
        value: '',
    });
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [checkboxTags, setCheckboxTags] = useState([]);
    const originalTags = useRef(checkboxTags);
    const inputRef = useRef(null);
    useEffect(() => {
        if (tagInput.visible && inputRef.current !== null) {
            inputRef.current.focus();
        }
    }, [tagInput.visible]);
    const getTagState = (tag) => {
        const count = selectedProjects.reduce((acc, project) => (project.tags.includes(tag) ? acc + 1 : acc), 0);
        if (count === selectedProjects.length) {
            return 'on';
        }
        if (count > 0) {
            return 'partial';
        }
        return 'off';
    };
    const fetchTags = async () => {
        const teamTags = await TeamAPI.fetchCurrentTeamTags(session.connection);
        const tags = teamTags.list.map((tag) => {
            var _a;
            return ({
                title: tag.key,
                key: tag.key,
                id: tag.tagId,
                abbreviation: tag.abbreviation,
                check: getTagState(tag.key),
                color: (_a = tag.color) !== null && _a !== void 0 ? _a : deriveTagColor(tag.key),
                isPinned: tag.isPinned,
            });
        });
        setCheckboxTags(tags);
        originalTags.current = tags;
    };
    useEffect(() => {
        void fetchTags();
    }, []);
    const applyNewTags = async () => {
        setUpdateInProgress(true);
        const checkedTags = checkboxTags
            .filter((tg) => tg.check === 'on')
            .map((tg) => tg.title);
        const partiallyCheckedTags = checkboxTags
            .filter((tg) => tg.check === 'partial')
            .map((tg) => tg.title);
        const promises = selectedProjects.map((project) => {
            const partiallyCheckedTagsOnProject = project.tags.filter((tg) => partiallyCheckedTags.includes(tg));
            const originalTeamTags = originalTags.current.map((teamTag) => teamTag.title);
            const tagsFromSharedProject = project.tags.filter((tg) => !originalTeamTags.includes(tg));
            const allCheckedTags = [
                ...new Set([...checkedTags, ...partiallyCheckedTagsOnProject, ...tagsFromSharedProject])
            ];
            return ProjectAPI.updateTags(session.connection, project, allCheckedTags);
        });
        await Promise.allSettled(promises);
        setUpdateInProgress(false);
        onRefetchTags();
        onCancel();
    };
    const updateTags = async (tag, action) => {
        setUpdateInProgress(true);
        let updatedTags = [...checkboxTags];
        if (action === 'delete') {
            const promises = selectedProjects.map((project) => {
                const newTags = project.tags.filter((t) => t !== tag);
                return ProjectAPI.updateTags(session.connection, project, newTags);
            });
            await Promise.allSettled(promises);
            updatedTags = checkboxTags.filter((tg) => tg.title !== tag);
        }
        else if (tag !== '') {
            const color = deriveTagColor(tag);
            const abbreviation = tag.slice(0, 2);
            const addedTag = {
                title: tag,
                key: tag,
                color,
                check: 'off',
                abbreviation,
                id: 0,
            };
            updatedTags = [
                ...checkboxTags,
                addedTag,
            ];
            const result = await TeamAPI.addCurrentTeamTag(session.connection, tag, abbreviation, color, true);
            if (result.isFail() && result.err() === 'duplicate-tag') {
                void message.error(txt('duplicateTag'));
            }
            void fetchTags();
            setTagInput({ visible: false, value: '' });
        }
        setCheckboxTags(updatedTags);
        setUpdateInProgress(false);
    };
    const handleInputChange = ({ target: { value } }) => setTagInput(Object.assign(Object.assign({}, tagInput), { value }));
    const showTagInput = () => setTagInput(Object.assign(Object.assign({}, tagInput), { visible: true }));
    const handleTagCheckChange = (updatedTag) => {
        const updatedTags = checkboxTags.map((tag) => (tag.id === updatedTag.id ? updatedTag : tag));
        setCheckboxTags(updatedTags);
    };
    return (_jsxs("div", { className: styles.tags, children: [_jsx("h4", { children: txt('addTags') }), _jsxs("div", { className: styles.dropdownForm, children: [checkboxTags.length === 0 ? (_jsx("p", { className: styles.noTags, children: txt('noTags') })) : null, !updateInProgress ? (checkboxTags.map((tag) => (_jsx(TagCheckbox, { tag: tag, onTagCheckChange: (tg) => handleTagCheckChange(tg), onDeleteTag: () => updateTags(tag.title, 'delete'), onRefetchProjects: onRefetch }, tag.title)))) : _jsx(Spin, { indicator: _jsx(LoadingOutlined, { style: { fontSize: 24 }, spin: true }) })] }), _jsx("div", { children: tagInput.visible ? (_jsx(Input, { ref: inputRef, style: { width: 110, margin: 3 }, value: tagInput.value, onChange: handleInputChange, onBlur: () => updateTags(tagInput.value.trim(), 'add'), onPressEnter: () => updateTags(tagInput.value.trim(), 'add') })) : (_jsxs(Tag, { className: styles.newTag, onClick: showTagInput, children: [_jsx(PlusOutlined, {}), "\u00A0", txt('newTag'), "\u00A0"] })) }), _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { size: "small", onClick: onCancel, children: txt('cancel') }), _jsx(Button, { size: "small", type: "primary", onClick: applyNewTags, disabled: JSON.stringify(originalTags.current) === JSON.stringify(checkboxTags), children: txt('apply') })] })] }));
};
export default TagsBulkEditDialog;
